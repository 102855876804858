import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import TVLLoader from './TVLLoader/TVLLoader';
import NetworksToggle from 'components/NetworksToggle/NetworksToggle';
import { useConnectWallet } from 'features/home/redux/hooks';
import { useFetchBalances, useFetchVaultsData, useFetchApys } from '../../redux/hooks';
import VisiblePools from '../VisiblePools/VisiblePools';
import styles from './styles';
import { usePoolsTvl, useUserTvl } from '../../hooks/usePoolsTvl';
import { formatGlobalTvl } from 'features/helpers/format';
import { useFetchBifibuyback } from 'features/vault/redux/fetchBifiBuyback';
import { getNetworkFriendlyName } from '../../../helpers/getNetworkData';

const FETCH_INTERVAL_MS = 15 * 1000;

const useStyles = makeStyles(styles);

const PoolHeading = styled.div`
  width: 100%;
  display: flex;
  //align-items: center;
  justify-content: space-between;

  // @media only screen and (max-width: 600px) {
  //   flex-direction:column;
  // }
`

export default function Pools() {
  const { t } = useTranslation();
  const { web3, address } = useConnectWallet();
  const { pools, fetchVaultsData, fetchVaultsDataPending, fetchVaultsDataDone } =
    useFetchVaultsData();
  const { tokens, fetchBalances, fetchBalancesPending, fetchBalancesDone } = useFetchBalances();
  const { apys, fetchApys, fetchApysDone } = useFetchApys();
  const { bifibuyback, fetchBifibuyback, fetchBifibuybackDone } = useFetchBifibuyback();
  const { poolsTvl } = usePoolsTvl(pools);
  const { userTvl } = useUserTvl(pools, tokens);
  const classes = useStyles();

  useEffect(() => {
    fetchApys();
    const id = setInterval(fetchApys, FETCH_INTERVAL_MS);
    return () => clearInterval(id);
  }, [fetchApys]);

  useEffect(() => {
    fetchBifibuyback();
    const id = setInterval(fetchBifibuyback, FETCH_INTERVAL_MS);
    return () => clearInterval(id);
  }, [fetchBifibuyback]);

  useEffect(() => {
    const fetch = () => {
      if (address && web3 && !fetchBalancesPending) {
        fetchBalances({ address, web3, tokens });
      }
      if (!fetchVaultsDataPending) {
        fetchVaultsData({ web3, pools });
      }
    };
    fetch();

    const id = setInterval(fetch, FETCH_INTERVAL_MS);
    return () => clearInterval(id);

    // Adding tokens and pools to this dep list, causes an endless loop, DDoSing the api
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, web3, fetchBalances, fetchVaultsData]);

  const chainNameLowercase = getNetworkFriendlyName().toLowerCase();
  const chainBifibuyback =
    fetchBifibuybackDone && chainNameLowercase in bifibuyback
      ? bifibuyback[chainNameLowercase].buybackUsdAmount
      : undefined;

  const activePoolCount = pools.filter(pool => pool.status === 'active').length;

  return (
    <Grid container className={classes.container} style={{marginTop:'6rem'}}>

      <PoolHeading>
        <Grid >
          {/* <h1 className={classes.title}>{t('Vault-Network')}</h1>  */}
          {/* <NetworksToggle /> */}
          {fetchVaultsDataDone && activePoolCount && (
            <>
              <span className={classes.title}>{`${activePoolCount} ${t('Vault-MainTitle')}`}</span>

              {/* <span
                className={classes.text}
                style={{
                  fontSize: 15,
                  fontWeight: 'bold',
                  marginTop: '25px',
                  verticalAlign: 'bottom',
                }}
              >
                We are committed to add atleast 5 new vaults per week{' '}
              </span> */}
              <p style={{fontWeight:'600'}}>
                We are committed to add atleast 5 new vaults per week{' '}
              </p>
            </>
          )}
        </Grid>
        <Grid  >
          <div >
            <p style={{fontSize:'2rem',color:'#000',fontWeight:'600',textAlign:'right',margin:'0'}}>
              TVL{' '}
              {fetchVaultsDataDone && poolsTvl > 0 ? (
                formatGlobalTvl(poolsTvl)
              ) : (
                <TVLLoader className={classes.titleLoader} />
              )}
            </p>

            {fetchBifibuybackDone && chainBifibuyback && (
              <p style={{fontWeight:'600',textAlign:'right'}} >
                {t('Vault-BifiBuyback', { amount: formatGlobalTvl(chainBifibuyback) })}
              </p>
            )}

            <p style={{fontWeight:'600',textAlign:'right'}}>
              {t('Vault-Deposited')}{' '}
              {fetchVaultsDataDone && fetchBalancesDone ? (
                formatGlobalTvl(userTvl)
              ) : (
                <TVLLoader className={classes.titleLoader} />
              )}
            </p>

            <p style={{fontWeight:'600',textAlign:'right'}}>
              <AllInclusiveIcon className={classes.infinityIcon} />
              {t('Vault-AutocompoundingNote')}
            </p>
          </div>
        </Grid>
      </PoolHeading>

      <VisiblePools
        pools={pools}
        apys={apys}
        tokens={tokens}
        fetchBalancesDone={fetchBalancesDone}
        fetchApysDone={fetchApysDone}
        fetchVaultsDataDone={fetchVaultsDataDone}
      />
    </Grid>
  );
}
