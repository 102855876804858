import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import Menu from '@material-ui/icons/Menu';
import Close from '@material-ui/icons/Close';
import WbSunny from '@material-ui/icons/WbSunny';
import NightsStay from '@material-ui/icons/NightsStay';
import { getNetworkBuyUrl } from '../../features/helpers/getNetworkData';
import styles from './styles';

const useStyles = makeStyles(styles);

const Text = styled.a`
  font-size: 1.25rem;
  text-decoration: none;

  &: hover{
    color: rgb(2, 169, 92);
    text-decoration: none;
  }
`
const LogoText = styled.p`
  font-size: 2rem;
  font-weight: 600;
  margin: 0 0 2.2rem 0;
`

const Header = ({ links, isNightMode, setNightMode }) => {
  const { chain } = useParams();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const classes = useStyles();
  const { t } = useTranslation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

// to={`/${chain}`}

  return (
    <AppBar className={`${classes.appBar} ${classes.dark}`} position="relative" style={{position:'fixed',width: '100%'}}>
      <Toolbar className={classes.container}>
        <a href='http://donorlanding.s3-website-us-west-2.amazonaws.com' > 
          <Button className={classes.title}>
            <Hidden xsDown style={{display:'flex',alignItems:'center'}}>
              <img
                alt="BIFI"
                src={require(`images/BIFI-logo.svg`)}
              //  height={'40px'}
              //  className={classes.logo}
                style={{width:'6rem',height:'6rem'}}
              />
              <LogoText
                
              >Donor</LogoText>
              
            </Hidden>
            <Hidden smUp>
              <img
                alt="BIFI"
                src={require(`images/BIFI-logo.png`)}
               // height={'35px'}
               // className={classes.logo}
                style={{width:'4rem',height:'4rem'}}
              />
            </Hidden>
          </Button>
        </a>
        {/* <Hidden smDown>
          <IconButton
            style={{ verticalAlign: 'bottom', display: 'inline', marginBottom: '-11px' }}
            onClick={setNightMode}
            className={classes.icon}
          >
            Darkmode: {isNightMode ? 'Off' : 'On'}
          </IconButton>
        </Hidden> */}

        <div className={classes.middleNav}>
          {/* <Hidden smDown>
            {renderLink('vote', t('vote'), 'vote-yea', classes)}
            {renderLink('dashboard', t('stats'), 'chart-bar', classes)}
            {renderLink('docs', t('docs'), 'book', classes)}
            {renderLink('forum', t('forum'), 'comments', classes)}
          </Hidden>
          {renderLink('buy', t('buy'), 'dollar-sign', classes)} */}
          {/* <Link className={classes.btnBoost} to={`/${chain}/stake`}>
            <img alt="Boost" src={require('images/stake/boost.svg')} />
          </Link> */}

          <Hidden smDown>
            <Text target='_blank' href='http://donorlanding.s3-website-us-west-2.amazonaws.com/earn' className={classes.link} style={{ marginLeft: '12px', marginRight: '12px' }}>
              Earn
            </Text>
            <Text className={classes.link} style={{ marginLeft: '12px', marginRight: '12px' }}>
              Donate  
            </Text>
            <Text className={classes.link} style={{ marginLeft: '12px', marginRight: '12px' }}>
              Fundraise for
            </Text>
            <Text className={classes.link} style={{ marginLeft: '12px', marginRight: '12px' }}>
              Docs  
            </Text>
            <Text className={classes.link} style={{ marginLeft: '12px', marginRight: '12px' }}>
              How it Works
            </Text>
          
          </Hidden>
        </div>

        <Hidden smDown implementation="css">
          <div className={classes.collapse}>{links}</div>
        </Hidden>
        <Hidden mdUp>
          <IconButton
            className={classes.iconButton}
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>

      <Hidden mdUp implementation="js">
        <Drawer
          variant="temporary"
          anchor={'right'}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={handleDrawerToggle}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            className={classes.closeButtonDrawer}
          >
            <Close />
          </IconButton>
          <div className={classes.appResponsive}>{links}</div>
          <div style={{ textAlign: 'center' }}>
            <LinkSidebar name="vote" label={t('Earn')} icon="vote-yea" classes={classes} />
            <LinkSidebar name="dashboard" label={t('Donate')} icon="chart-bar" classes={classes} />
            <LinkSidebar name="docs" label={t('Fundraise for')} icon="book" classes={classes} />
            <LinkSidebar name="forum" label={t('Docs')} icon="comments" classes={classes} />
            <LinkSidebar name="buy" label={t('How it Works')} icon="dollar-sign" classes={classes} />
            {/* <IconButton onClick={setNightMode} className={classes.icon}>
              {isNightMode ? <WbSunny /> : <NightsStay />}
            </IconButton> */}
          </div>
        </Drawer>
      </Hidden>
    </AppBar>
  );
};

const renderLink = (name, label, icon, classes) => {
  return (
    <a
      href={getLinkUrl(name)}
      target="_blank"
      rel="noopener noreferrer"
      className={classes.link}
      style={{ marginLeft: '5px', marginRight: '5px' }}
    >
      {/* <i className={`fas fa-${icon} ${classes.icon}`} /> */}
      <span>{label}</span>
    </a>
  );
};

const LinkSidebar = ({ name, label, icon, classes }) => (
  <div style={{ width: '100%', paddingTop: '10px',textAlign:'left',marginLeft:'1rem' }}>{renderLink(name, label, icon, classes)}</div>

);

const getLinkUrl = name => {
  return name === 'buy' ? getNetworkBuyUrl() : `https://${name}.beefy.finance`;
};

export default Header;
