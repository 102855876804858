import React, { useCallback, useMemo } from 'react';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import BigNumber from 'bignumber.js';
import { makeStyles } from '@material-ui/core/styles';
import { formatTvl } from 'features/helpers/format';
import { byDecimals } from 'features/helpers/bignumber';
import styles from './styles';
import PoolPaused from './PoolPaused/PoolPaused';
import PoolTitle from './PoolTitle/PoolTitle';
import LabeledStat from './LabeledStat/LabeledStat';
import ApyStats from './ApyStats/ApyStats';
import { usePoolApr } from '../../../stake/redux/subscription';
import { PoolBoosts } from './PoolBoosts/PoolBoosts';
import { getRetireReason } from './RetireReason/RetireReason';
import { Button } from '@material-ui/core';

const useStyles = makeStyles(styles);

const Styled = styled(AccordionSummary)`
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
` 

const PoolSummary = ({
  pool,
  launchpool,
  toggleCard,
  balanceSingle,
  sharesBalance,
  apy,
  fetchBalancesDone,
  fetchApysDone,
  fetchVaultsDataDone,
  multipleLaunchpools = false,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const launchpoolApr = usePoolApr(launchpool ? launchpool.id : null);
  const vaultStateTitle = useMemo(() => {
    let state =
      pool.status === 'eol'
        ? t(getRetireReason(pool.retireReason))
        : pool.depositsPaused
        ? t('Vault-DepositsPausedTitle')
        : null;

    if (launchpool) {
      state = t('Stake-BoostedBy', { name: launchpool.name });
    }

    if (pool.experimental) {
      state = t('Vault-Experimental');
    }

    return state === null ? (
      ''
    ) : (
      <PoolPaused
        message={t(state)}
        isBoosted={!!launchpool}
        isExperimental={!!pool.experimental}
      />
    );
  }, [pool, launchpool, t]);

  const balanceUsd =
    balanceSingle > 0 && fetchVaultsDataDone ? formatTvl(balanceSingle, pool.oraclePrice) : '';
  const deposited = byDecimals(
    sharesBalance.multipliedBy(new BigNumber(pool.pricePerFullShare)),
    pool.tokenDecimals
  );
  const depositedUsd =
    deposited > 0 && fetchVaultsDataDone ? formatTvl(deposited, pool.oraclePrice) : '';

  const onSummaryClick = useCallback(
    e => {
      if (!e.target || !e.target.classList.contains('tooltip-toggle')) {
        toggleCard();
      }
    },
    [toggleCard]
  );

  return (
    <Styled
      className={
        pool.status === 'eol'
          ? classes.detailsRetired
          : pool.depositsPaused
          ? classes.detailsPaused
          : classes.details
      }
      style={{
        justifyContent: 'space-between',
        borderRadius: '10px',
      }}
      onClick={onSummaryClick}
    >
      <Grid
        container
        alignItems="center"
        style={{ paddingTop: '20px', paddingBottom: '0', borderRadius: '10px' }}
      >
        {vaultStateTitle}
        <PoolBoosts poolName={pool.name} earnedTokenAddress={pool.earnedTokenAddress} />
        <Grid
          item
          xs={12}
          className={`${classes.item} ${classes.itemTitle}`}
          style={{ borderRadius: '10px' }}
        >
          <PoolTitle
            name={pool.name}
            logo={pool.logo}
            poolId={pool.id}
            description={t('Vault-Description', { vault: pool.tokenDescription })}
            launchpool={launchpool}
            addLiquidityUrl={pool.addLiquidityUrl}
            removeLiquidityUrl={pool.removeLiquidityUrl}
            buyTokenUrl={pool.buyTokenUrl}
            assets={pool.assets}
            multipleLaunchpools={multipleLaunchpools}
          />
        </Grid>
        {/* <Grid item xs={6} lg={2} className={`${classes.item} ${classes.itemStats}`}>
          <LabeledStat
            value={formatDecimals(balanceSingle)}
            subvalue={balanceUsd}
            label={t('Vault-Wallet')}
            isLoading={!fetchBalancesDone}
            className={classes.itemInner}
          />
        </Grid> */}
        <Grid item xs={6} lg={2} xl={2} className={`${classes.item} ${classes.itemStats}`}>
          <LabeledStat
            value={formatDecimals(deposited)}
            subvalue={depositedUsd}
            label={t('Vault-Deposited')}
            isLoading={!fetchBalancesDone}
            className={classes.itemInner}
          />
        </Grid>
        <ApyStats
          apy={apy}
          launchpoolApr={launchpoolApr}
          isLoading={!fetchApysDone}
          itemClasses={`${classes.item} ${classes.itemStats}`}
          itemInnerClasses={classes.itemInner}
        />
        <Grid item xs={4} className={`${classes.item} ${classes.itemStats}`}>
          <LabeledStat
            value={formatTvl(pool.tvl, pool.oraclePrice)}
            label={t('Vault-TVL')}
            isLoading={!fetchVaultsDataDone}
            className={classes.itemInner}
          />
        </Grid>

        {pool?.addLiquidityUrl ? (
          <Grid item xs={6} lg={2} className={`${classes.item} ${classes.itemStats}`}>
            <Button
              className={classes.buttonOutlined}
              href={pool.addLiquidityUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>{t('Add-Liquidity')}</span>
            </Button>
          </Grid>
        ) : (
          ''
        )}
        {pool?.removeLiquidityUrl ? (
          <Grid item xs={6} lg={2} className={`${classes.item} ${classes.itemStats}`}>
            <Button
              className={classes.buttonOutlined}
              href={pool.removeLiquidityUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>{t('Remove-Liquidity')}</span>
            </Button>
          </Grid>
        ) : (
          ''
        )}
        {pool?.buyTokenUrl ? (
          <Grid item xs={6} lg={2} className={`${classes.item} ${classes.itemStats}`}>
            <Button
              className={classes.buttonContained}
              href={pool.buyTokenUrl}
              target="_blank"
              rel="noopener noreferrer"
              style={{background:'rgb(2, 169, 92)'}}
            >
              <span>{t('Buy-Token')}</span>
              {'\u00A0\u00A0'}
            </Button>
          </Grid>
        ) : (
          ''
        )}

        {/* <Grid item xs={6} lg={2} className={`${classes.item} ${classes.itemStats}`}>
          <Button className={classes.buttonOutlined}>
            hii
          </Button>
        </Grid>
        <Grid item xs={6} lg={2} className={`${classes.item} ${classes.itemStats}`}>
          <Button className={classes.buttonContained}>
            hii
          </Button>
        </Grid> */}
      </Grid>
    </Styled>
  );
};

const formatDecimals = number => {
  return number >= 10 ? number.toFixed(5) : number.isEqualTo(0) ? 0 : number.toFixed(6);
};

export default PoolSummary;
