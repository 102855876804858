export const bscTestPools = [
  {
    id: 'test-test-bnb',
    logo: 'degens/banana-bnb.svg',
    name: 'TEST-BNB LP',
    token: 'TEST-BNB LP',
    tokenDescription: 'Donor',
    tokenAddress: '0xAc4FfC5dc71F42438d2f51FfF1c59eBef5d91e9B',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeBANANA-BNB',
    earnedTokenAddress: '0xA6EBDa05818a976296B869f366e3ADAa99c39f1d',
    earnContractAddress: '0xA6EBDa05818a976296B869f366e3ADAa99c39f1d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-banana-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Donor',
    assets: ['TEST', 'BNB'],
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/ETH/0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?inputCurrency=0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95&outputCurrency=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  },




  {
    id: 'test',
    logo: 'degens/BITI.png',
    name: 'Donor',
    token: 'Donor',
    tokenDescription: 'Donor',
    tokenAddress: '0x1b2380962216187e3726e03569666c35A5b7E93d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'DonorReceipt',
    earnedTokenAddress: '0xE9E2EED979A4F844B598CEa20E0642aBbC0DA813',
    earnContractAddress: '0xE9E2EED979A4F844B598CEa20E0642aBbC0DA813',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'DonorReceipt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Donor',
    assets: [ 'BNB'],
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/ETH/0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?inputCurrency=0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95&outputCurrency=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  },
];
